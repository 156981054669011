* {
	margin: 0;
}

html, body {
	height: 100%;
}

a {
	outline: 0;
}

.uk-container {
	max-width: 1180px;
}

.container {
	min-height: 100%;
	margin-bottom: -40px;
	padding-bottom: 80px;
}

.container:after {
	content: "";
	display: block;
}

.site-footer, .page-wrap:after {
  height: 40px;
}

.site-footer > .copyright {
	text-align: center;
	background-color: black;
	color: #ccc;
	line-height: 40px;
}

header {
	margin: 20px 0;
}

.logo {
	height: 65px;
	width: 200px;
	float: left;
}

.uk-form .uk-button {
	border-radius: 5px;
	font-size: 16px;
	line-height: 38px;
	color: white;
}

.icon-flag, .icon-flag-disabled {
	width: 35px;
	height: 35px;
	display: inline-block;
}

.icon-flag-disabled {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	filter: gray;
	-webkit-filter: grayscale(100%);
}

.icon-flag-disabled:hover {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
	-webkit-filter: grayscale(0%);
}

.avatar img {
	width: 150px;
	height: 150px;
	padding: 5px;
	margin-bottom: 20px;
	border: 2px solid #ddd;
	background-color: white;
	overflow: hidden;
}

.people {
	margin: 20px 0;
}

.avatar a:hover img {
	border: 2px solid #444;
}

.alumni {
	display: block;
	margin: 5px 0;
	padding: 5px;
}

.alumni a {
	color: #444;
}

.alumni .avatar img {
	width: 75px;
	height: 75px;
	padding: 2px;
	border: 1px solid #ddd;
	margin: 0 10px 0 0;
	display: inline-block;
}

.alumni .person {
	display: inline-block;
}

.avatar h3 {
	color: #444;
	margin: 0;
}

.avatar h4 {
	color: #888;
	margin: 0;
	font-style: italic;
}

.person {
	padding: 10px;
}

ul {
	margin-bottom: 0;
}

.uk-search::before {
	left: 10px;
}

/* Homepage Overlay */
.caption {
	background-color: rgba(43, 46, 50, 0.9);
	left: 0;
	bottom: 30px;
	color: #fff;
	padding: 15px 20px;
	position: absolute;
	right: 30px;
	z-index: 5;
}

.caption h1, .caption h2 {
	color: #fff;
}

.tm-article-content h4 {
	margin-top: 15px;
}

.tm-article-content h4 a {
	color: #2a2d31;
}

.tm-grid-block > * {
	border-left: none;
}

.tm-container {
	margin: 20px auto;
}

#infscr-loading img {
    display : block;
    margin : 1em auto;
}

.publications + .uk-pagination {
  display: none;
}

@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format('woff2'), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.tm-toolbar {
	padding: 10px 20px;
}

/* Stupid Changes */
html {
	background: #fff none repeat scroll 0 0;
}

.uk-navbar {
    background: #fff none repeat scroll 0 0;
}

.uk-navbar-nav > li > a {
	color: #444;
	font-size: 16px;
	color: #4f9b9d;
	&:hover {
		background-color: inherit;
		color: #4f9b9d;
		border-bottom: 3px solid #d27474;
	};
}

.caption {
	background: inherit;
}

/* Navbar Overrides */

.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a:focus,
.uk-navbar-nav > li.uk-open > a {
    background-color: inherit;
    box-shadow: none;;
    color: #d27474;
    outline: medium none;
    position: relative;
    z-index: 1;
}

.uk-navbar-nav > li.uk-active > a {
    background-color: #fff;
    color: #d27474;
    background-image: none;
   border-bottom: 3px solid #d27474;
}

.uk-nav-navbar > li > a:hover,
.uk-nav-navbar > li > a:focus {
    background: #d27474 none repeat scroll 0 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset;
    color: #fff;
    outline: medium none;
}

/* Social Buttons */
.tm-social-icon-buttons > .uk-icon-twitter {
    background-color: #019ad1;
}

.tm-social-icon-buttons > .uk-icon-linkedin {
    background-color: #069;
}

.tm-social-icon-buttons > .uk-icon-envelope-o {
    background-color: #d27474;
}

/* Emphasize */
em {
    color: #4f9b9d;
}

/* News Images */
.tm-article-featured-image {
	a img {
		height: 150px;
		width: 100%;
		object-fit: cover;
	}
}

.uk-thumbnail-caption {
	color: #4f9b9d;
	height: 50px;
}

.uk-article-title {
	font-size: 24px;
}

.uk-overlay img {
	transform: none!important;
}

.tm-article .tm-article-date-true .tm-article-date {
	left: -65px;
	width: 40px;
}

.uk-article-title {
	line-height: 30px;
}

.panel-box-red {
	background-color: #d27474!important;
}

.panel-box-green {
	background-color: #9cba8f!important;
    text-align: center!important;
	h3 {
		line-height: 1.8;
		font-size: 50px;
	}
}

.publications {
	color: #2a2d31!important;
	a, em {
		color: #2a2d31!important;
	}
}